import { TokenEngine } from '@vp/vp-js-token-engine'

// The accessible text needs to be passed in as a string not a component so must use token engine
export function getCarouselAccessibleTextForDots (slideAccessibleText: string, numberOfSlides = 0) {
  const tokenEngine = new TokenEngine()
  const accessibleTextForDots: Array<string> = []

  for (let index = 1; index <= numberOfSlides; index++) {
    accessibleTextForDots.push(tokenEngine.replace(slideAccessibleText, { slideNumber: index }))
  }
  return accessibleTextForDots
}
