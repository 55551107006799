import {
  Carousel,
  CarouselSlide,
  Box,
  SquareImageContainer,
  Visible,
  FluidImage,
  Hidden,
  Button,
  Spinner,
  StandardTileContents,
  StandardTilePrice,
  AspectRatio,
  FlexBox,
  Typography
} from '@vp/swan'
import { useLocalization } from '../../localization/LocalizationContext'
import { PriceDisplay } from '@vp/price-platform'
import { getCarouselAccessibleTextForDots } from './accessibleNameForDots'
import { useIdentity, useUserContext } from '@vp/ubik-context'
import { getEditUrl } from '../../clients/albelliClient'
import { useState } from 'react'
import { useProductData } from '../../context/ProductContext'
import { useTrackStudioPageView } from './tracking'

export type TileProps = {
  design: {
    predesignId: string;
    predesignImages: string[];
  },
  showSignInModal: (editUrl: string) => void;
}
export const Tile = ({ design, showSignInModal }: TileProps) => {
  const { predesignId, predesignImages } = design
  const goToPrevSlideText = useLocalization('gotoPrevSlide')
  const goToNextSlideText = useLocalization('gotoNextSlide')
  const slideNumberText = useLocalization('slideNumber')
  const loadingAccessibilityLabelText = useLocalization('loadingAccessibilityLabel')
  const editDesignText = useLocalization('editDesign')

  const { mpvId, selectedOptions, productKey } = useProductData()

  const [loading, setLoading] = useState(false)

  const locale = useUserContext().locale

  const { identity, isSignedIn } = useIdentity()

  const trackStudioPageView = useTrackStudioPageView(predesignId)

  const handleDesignSelected = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    const authHeader = identity?.authorizationHeader
    if (!authHeader) {
      return
    }

    setLoading(true)
    const editUrl = await getEditUrl(predesignId, productKey, selectedOptions, locale, authHeader)

    // Fire the tracking event for studio page view before we navigate away from vp
    trackStudioPageView()

    setLoading(false)

    if (isSignedIn) {
      // If the user is already signed in, then redirect to the edit URL
      window.location.href = editUrl
    } else {
      // If the user is not signed in, show the sign in modal with the redirect URL
      showSignInModal(editUrl)
    }
  }

  return (
    <>
      <Carousel
        slidesToShow={1}
        progressIndicator='dots'
        slidesToScroll={1}
        swipeToSlide
        adaptiveHeight
        dots
        pt={6}
        px={5}
        accessibleTextForPrevious={goToPrevSlideText}
        accessibleTextForNext={goToNextSlideText}
        className='carousel-slider'
        dotsClass='slick-dots calendar-dots'
        accessibleTextForDots={getCarouselAccessibleTextForDots(slideNumberText, predesignImages.length)}
      >
        {predesignImages.map((newPredesignImage) => (
          <CarouselSlide mb={6} key={`${predesignId}-${newPredesignImage}`}>
            {loading && (
              <AspectRatio ratio={1} className='wall-calendar-image-preloader-overlay'>
                <Spinner size='super' accessibleText={loadingAccessibilityLabelText} />
              </AspectRatio>
            )}
            <Box p={3}>
              {/* TabIndex/button is for accessibility so the user can focus on tab */}
              <Button skin='unstyled' onClick={handleDesignSelected} tabIndex={0} style={{ display: 'block', width: '100%', }}>
                <SquareImageContainer>
                  <Visible xs>
                    <FluidImage src={newPredesignImage} />
                  </Visible>
                  <Hidden xs>
                    <FluidImage src={newPredesignImage} />
                  </Hidden>
                </SquareImageContainer>
              </Button>
            </Box>
          </CarouselSlide>
        ))}
      </Carousel>
      <StandardTileContents className='tile-contents'>
        <StandardTilePrice>
          <PriceDisplay mpvId={mpvId} selections={selectedOptions} />
        </StandardTilePrice>
      </StandardTileContents>
      <Visible xs>
        <FlexBox justifyContent='center' mb={7}>
          <Button skin='primary' onClick={handleDesignSelected}>
            <Typography>{editDesignText}</Typography>
          </Button>
        </FlexBox>
      </Visible>
    </>
  )
}
