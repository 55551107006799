import {
  ModalDialog,
  ModalDialogContent,
  ModalDialogNav,
  ModalDialogCloseButton,
  ModalDialogHeader,
  ModalDialogTitle,
  ModalDialogBody,
  ModalDialogFooter,
  ModalDialogButtons,
  Button,
} from '@vp/swan'
import { useIdentity } from '@vp/ubik-context'
import { useLocalization } from '../../localization/LocalizationContext'
import {
  dismissButtonTrackingProps,
  maybeLaterButtonTrackingProps,
  signInButtonTrackingProps,
} from './signInModalTrackingProps'
import { useTrackSignInModal } from './tracking'

export interface SignInModalProps {
  redirectUrl: string | undefined;
  open: boolean;
  onClose: () => void;
}

// TODO redirecturl shouldnt be undefined
export const SignInModal = ({
  redirectUrl,
  open,
  onClose,
}: SignInModalProps) => {
  // Localization
  const titleText = useLocalization('signInModalTitle')
  const descriptionText = useLocalization('signInModalDescription')
  const primaryButtonLabelText = useLocalization('signInModalPrimaryAction')
  const secondaryButtonLabelText = useLocalization(
    'signInModalSecondaryAction'
  )

  const identity = useIdentity()
  const { isSignedIn } = identity
  const {
    trackContinueWithSignIn,
    trackContinueWithoutSignIn,
    trackModalDismissed,
    useTrackOpened,
  } = useTrackSignInModal()

  const handleContinueWithoutSignIn = () => {
    trackContinueWithoutSignIn()
    window.location.href = redirectUrl
  }

  const handleContinueWithSignIn = () => {
    trackContinueWithSignIn()
    identity?.auth?.signIn([
      {
        key: 'redirectUrl',
        value: encodeURIComponent(redirectUrl),
      },
    ])
    onClose()
  }

  const handleModalDismissed = () => {
    trackModalDismissed()
    onClose()
  }

  // Track when the modal is opened
  useTrackOpened(open)

  return (
    <ModalDialog
      bodyWidth='capped'
      isOpen={open && !isSignedIn}
      onRequestDismiss={handleModalDismissed}
    >
      <ModalDialogContent aria-labelledby='sign-in-modal-dialog-title'>
        <ModalDialogNav>
          <ModalDialogCloseButton
            accessibleText='Close'
            {...dismissButtonTrackingProps}
          />
        </ModalDialogNav>
        <ModalDialogHeader>
          <ModalDialogTitle id='sign-in-modal-dialog-title'>
            {titleText}
          </ModalDialogTitle>
        </ModalDialogHeader>
        <ModalDialogBody>{descriptionText}</ModalDialogBody>
        <ModalDialogFooter pinned>
          <ModalDialogButtons>
            <Button
              skin='secondary'
              fontSize='small'
              onClick={handleContinueWithoutSignIn}
              {...maybeLaterButtonTrackingProps}
            >
              {secondaryButtonLabelText}
            </Button>
            <Button
              skin='primary'
              fontSize='small'
              onClick={handleContinueWithSignIn}
              {...signInButtonTrackingProps}
            >
              {primaryButtonLabelText}
            </Button>
          </ModalDialogButtons>
        </ModalDialogFooter>
      </ModalDialogContent>
    </ModalDialog>
  )
}
