export const TILE_SPAN_DEFAULT = 3
export const TILE_SPAN_SM = 4
export const TILE_SPAN_XS = 6

export const isFirstColumn = (index: number, span: number) => {
  const colsPerRow = 12 / span
  return index % colsPerRow === 0
}

export const isLastRow = (
  designsToShow: number,
  index: number,
  span: number
) => {
  const colsPerRow = 12 / span
  const totalNumberOfRows = Math.ceil(designsToShow / colsPerRow)
  const currentRow = Math.ceil((index + 1) / colsPerRow)
  return currentRow === totalNumberOfRows
}
