import { Button, Typography } from '@vp/swan'
import { useLocalization } from '../localization/LocalizationContext'

interface LoadMoreButtonProps {
  onClick: () => void;
}

export const LoadMoreButton = ({ onClick }: LoadMoreButtonProps) => {
  const loadMoreText = useLocalization('loadMore')

  return (
    <Button onClick={onClick}>
      <Typography fontSize='small' fontWeight='bold'>
        {loadMoreText}
      </Typography>
    </Button>
  )
}
