import { createLogger } from '@vp/ubik-logging'
import { LoggerOptions } from '@vp/ubik-fragment-types'

const defaultLoggerOptions: LoggerOptions = { serviceName: 'gallery-albelli' }

let loggerInstance: ReturnType<typeof createLogger> | null = null

export const configureLogger = (options?: LoggerOptions) => {
  if (!loggerInstance) {
    loggerInstance = createLogger(options ?? defaultLoggerOptions)
  }
}

export const getLogger = () => {
  if (!loggerInstance) {
    throw new Error('Logger is not initialized. Call configureLogger() first.')
  }
  return loggerInstance
}

export default getLogger
