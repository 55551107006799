import getLogger from '../../node/logger'

const ALBELLI_DESIGN_BASE_URL = 'https://albelli.design.vpsvc.com'

type AlbelliEditorUrlRespose = {
  url: string
}

export async function getEditUrl (
  predesignedProductId: string,
  productKey: string,
  selectedOptions: Record<string, string>,
  locale: string,
  authHeader: string
): Promise<string> {
  const queryParams = new URLSearchParams({
    locale,
    selectedOptions: JSON.stringify(selectedOptions),
    vpProductKey: productKey,
    predesignedProductId: encodeURIComponent(predesignedProductId),
  })
  const url = `${ALBELLI_DESIGN_BASE_URL}/api/v1/editorUrl?${queryParams.toString()}`
  try {
    const response = await fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authHeader,
      },
    })

    const responseJSON = (await response.json()) as AlbelliEditorUrlRespose

    if (!response.ok || !responseJSON.url) {
      throw new Error(`Failed to fetch Albelli Editor Url: ${response.status}`)
    }

    return responseJSON.url
  } catch (e) {
    getLogger().error('Error getting editor URL', {
      error: e,
      predesignedProductId,
      productKey,
      selectedOptions,
      locale,
      authHeader,
      url
    })
    throw e
  }
}
