import { Column, FlexBox, GridContainer, Row } from '@vp/swan'
import classNames from 'classnames'
import { Tile, TileProps } from '../Tile'
import { useState } from 'react'
import { LoadMoreButton } from '../LoadMoreButton'
import { SignInModal } from '../SignInModal'
import { isFirstColumn, isLastRow, TILE_SPAN_DEFAULT, TILE_SPAN_SM, TILE_SPAN_XS } from '../../utils/layoutHelpers'
import { useTrackingProductListViewedEvent } from './tracking'

// We choose this number as number of designs to show on page load,
// because it guarantees we have full rows of designs regardless of
// desktop layout, tablet layout or mobile layout
const STEP_SIZE = 24

export const Tiles = ({
  designs
}: {
  designs: TileProps['design'][]
}) => {
  const [numDesignsShown, setNumDesignsShown] = useState(STEP_SIZE)
  const [signInRedirectUrl, setSignInRedirectUrl] = useState<string>()
  const [signInModalOpened, setSignInModalOpened] = useState(false)

  useTrackingProductListViewedEvent(numDesignsShown, designs)

  const extraColumns = Array(3).fill(
    <Column span={TILE_SPAN_DEFAULT} spanSm={TILE_SPAN_SM} spanXs={TILE_SPAN_XS} className='extra-cols' />
  )

  return (
    <>
      <GridContainer>
        <Row className='design-row' mb={9}>
          {designs.slice(0, numDesignsShown).map((design, index) => (
            <Column
              key={design.predesignId}
              span={3}
              spanSm={4}
              spanXs={6}
              alignItems='center'
              justifyContent='center'
              className={classNames('design-column', {
                // Each row show horizontal border across the page
                // Each column show left and right border, except that
                // left most tile does not show left border and
                // right most tile does not show right border
                'design-column-first-in-row-lg': isFirstColumn(index, TILE_SPAN_DEFAULT),
                'design-column-first-in-row-sm': isFirstColumn(index, TILE_SPAN_SM),
                'design-column-first-in-row-xs': isFirstColumn(index, TILE_SPAN_XS),
                'design-column-last-row-lg': isLastRow(numDesignsShown, index, TILE_SPAN_DEFAULT),
                'design-column-last-row-sm': isLastRow(numDesignsShown, index, TILE_SPAN_SM),
                'design-column-last-row-xs': isLastRow(numDesignsShown, index, TILE_SPAN_XS),
              })}
            >
              <Tile
                key={design.predesignId}
                design={design}
                showSignInModal={(editUrl) => {
                  setSignInModalOpened(true)
                  setSignInRedirectUrl(editUrl)
                }}
              />
            </Column>
          ))}
          {extraColumns}
        </Row>
      </GridContainer>
      {numDesignsShown < designs.length && (
        <FlexBox justifyContent='center' mb={9}>
          <LoadMoreButton onClick={() => setNumDesignsShown(numDesignsShown + STEP_SIZE)} />
        </FlexBox>
      )}

      <SignInModal
        open={signInModalOpened}
        onClose={() => setSignInModalOpened(false)}
        redirectUrl={signInRedirectUrl}
      />
    </>
  )
}
