export const dismissButtonTrackingProps = {
  'data-section': 'Save Your Designs Modal',
  'data-position': 1,
  'data-translation': 'Exit Button Clicked',
}
export const maybeLaterButtonTrackingProps = {
  'data-section': 'Save Your Designs Modal',
  'data-position': 2,
  'data-translation': 'Continue Without Signing In Clicked',
}
export const signInButtonTrackingProps = {
  'data-section': 'Save Your Designs Modal',
  'data-position': 3,
  'data-translation': 'Sign In Clicked',
}
