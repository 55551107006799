import { BoundedContent } from '@vp/swan'
import { useLogger, useSwanStyleKeys, useStyles, IdentityProvider } from '@vp/ubik-context'
import { PageId, useSetPriceConfigs } from '@vp/price-platform'
import { Title } from '../components/Title'
import { ProductCount } from '../components/ProductCount'
import { LocalizationProvider } from '../localization/LocalizationContext'
import { LocalizationDictionary } from '../localization/dictionaryEntries'
import { Tiles } from '../components/Tiles/Tiles'
import { stylesheet } from '../styles/styles.scss'
import { ProductProvider } from '../context/ProductContext'
import { useTrackCalendarPageEvent } from './tracking'

export interface CalendarFragmentProps {
  locale: string;
  tenant: string;
  mpvId: string;
  selectedOptions: Record<string, string>;
  merchandisableProductName: string;
  productKey: string;
  localizationDictionary: LocalizationDictionary;
  albelliDesigns: any;
}

export const CalendarsFragment = ({
  merchandisableProductName,
  productKey,
  localizationDictionary,
  albelliDesigns,
  mpvId,
  selectedOptions,
  locale,
  tenant,
}: CalendarFragmentProps) => {
  useStyles(stylesheet)
  const logger = useLogger()
  logger.log('Rendering fragment for a locale', locale)

  useSwanStyleKeys(['core', 'carousel', 'modalDialog', 'spinner', 'standardTile', 'visible'])

  // Setup the price configs to be used in the tiles
  useSetPriceConfigs({
    locale,
    tenant,
    pageId: PageId.Gallery, // TODO - do we need one for ADP
    tracking: {
      pageName: 'TODO',
      pageSection: 'TODO',
      pageStage: 'TODO',
    },
    developmentMode: true, // TODO
  })

  // Fire page tracking event once on load
  useTrackCalendarPageEvent(locale, mpvId)

  return (
    <IdentityProvider auth={{
      culture: locale,
      developmentMode: true,
      // options: undefined
    }}
    >
      <LocalizationProvider localizationDictionary={localizationDictionary}>
        <ProductProvider mpvId={mpvId} selectedOptions={selectedOptions} productKey={productKey} merchandisableProductName={merchandisableProductName}>
          <BoundedContent className='bounded-content'>
            <Title />
            <ProductCount productCount={albelliDesigns.length} />
            <Tiles
              designs={albelliDesigns}
            />
          </BoundedContent>
        </ProductProvider>
      </LocalizationProvider>
    </IdentityProvider>
  )
}
