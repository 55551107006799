import React, {
  createContext,
  useContext,
  PropsWithChildren,
} from 'react'
import { LocalizationDictionary, LocalizationKeys } from './dictionaryEntries'

const LocalizationContext = createContext<LocalizationDictionary | undefined>(
  undefined
)

export const useLocalization = (text: LocalizationKeys) => {
  const context = useContext(LocalizationContext)
  if (!context) {
    throw new Error(
      'useLocalization must be used within a LocalizationProvider'
    )
  }
  return context[text]
}

export const LocalizationProvider = ({
  children,
  localizationDictionary,
}: PropsWithChildren<{ localizationDictionary: LocalizationDictionary }>) => {
  return (
    <LocalizationContext.Provider value={{ ...localizationDictionary }}>
      {children}
    </LocalizationContext.Provider>
  )
}
