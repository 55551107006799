import { FlexBox, Typography } from '@vp/swan'
import { TokenReplace } from '@vp/vp-tokenized-fragment'
import { useLocalization } from '../localization/LocalizationContext'

export const ProductCount = ({ productCount }: { productCount: number }) => {
  const totalNumberOfProductsString = useLocalization(
    'totalNumberOfProductsString'
  )

  return (
    <FlexBox
      className='total-number-of-products'
      justifyContent='center'
      mb={9}
    >
      <Typography fontSize='small'>
        <TokenReplace
          tokenizedText={totalNumberOfProductsString}
          contextVariables={{ 'number of': productCount }}
        />
      </Typography>
    </FlexBox>
  )
}
