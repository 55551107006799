import { useProductData } from '../../context/ProductContext'
import { useEffect } from 'react'
import { getInitializedTracking } from '../../utils/tracking'

const SIGNIN_EVENT_LABEL = 'Sign-in Pop-Up'

enum TRACKING_EVENTS {
  SIGNIN_MODAL_VIEWED = 'SIGNIN_MODAL_VIEWED',
  SIGNIN_MODAL_CLOSED = 'SIGNIN_MODAL_CLOSED',
  SIGNIN_ACTION = 'SIGNIN_ACTION',
  SIGNIN_LATER_ACTION = 'SIGNIN_LATER_ACTION',
}

const TRACKING_EVENTS_LABELS = {
  [TRACKING_EVENTS.SIGNIN_MODAL_VIEWED]: {
    eventName: 'Pop-Up Viewed',
    eventLabel: SIGNIN_EVENT_LABEL,
  },
  [TRACKING_EVENTS.SIGNIN_MODAL_CLOSED]: {
    eventName: 'Pop-Up Closed',
    eventLabel: SIGNIN_EVENT_LABEL,
  },
  [TRACKING_EVENTS.SIGNIN_ACTION]: {
    eventName: 'Pop-Up Clicked',
    eventLabel: SIGNIN_EVENT_LABEL,
  },
  [TRACKING_EVENTS.SIGNIN_LATER_ACTION]: {
    eventName: 'Pop-Up Clicked',
    eventLabel: SIGNIN_EVENT_LABEL,
  },
}

// TODO - handle when getTracking is null
function trackSignInModalEvent (event: TRACKING_EVENTS, mpvId: string, eventDetail?: string) {
  getInitializedTracking().then((tracking) => {
    tracking.track(TRACKING_EVENTS_LABELS[event].eventName, {
      category: 'Pop-Up',
      label: TRACKING_EVENTS_LABELS[event].eventLabel,
      pageSection: 'Gallery',
      pageStage: 'Discover',
      pageName: `${mpvId}:Gallery`,
      eventDetail,
    })
  })
}

const useTrackOpened = (isModalOpen: boolean) => {
  const { mpvId } = useProductData()

  useEffect(() => {
    if (isModalOpen) {
      trackSignInModalEvent(TRACKING_EVENTS.SIGNIN_MODAL_VIEWED, mpvId)
    }
  }, [isModalOpen])
}

export const useTrackSignInModal = () => {
  const { mpvId } = useProductData()

  const trackContinueWithoutSignIn = () => {
    trackSignInModalEvent(TRACKING_EVENTS.SIGNIN_LATER_ACTION, mpvId, 'Continue without signing in')
  }

  const trackContinueWithSignIn = () => {
    trackSignInModalEvent(TRACKING_EVENTS.SIGNIN_ACTION, mpvId, 'Sign in')
  }

  const trackModalDismissed = () => {
    trackSignInModalEvent(TRACKING_EVENTS.SIGNIN_MODAL_CLOSED, mpvId)
  }

  return { trackContinueWithoutSignIn, trackContinueWithSignIn, trackModalDismissed, useTrackOpened }
}
