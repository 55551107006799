import { useEffect } from 'react'
import { getInitializedTracking } from '../utils/tracking'

// TODO - handle when getTracking is null
export const useTrackCalendarPageEvent = (locale: string, mpvId: string) => {
  const language = locale.substring(0, 2)

  useEffect(() => {
    getInitializedTracking().then((tracking) => {
      tracking.page(
      `${mpvId}:Option Selection:Gallery`,
      {
        pageSection: 'Gallery',
        pageStage: 'Discover',
        language,
        locale,
      })
    })
  }, [])
}
