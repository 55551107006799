import React, { createContext, useContext, PropsWithChildren } from 'react'

interface ProductContextProps {
  mpvId: string;
  selectedOptions: Record<string, string>;
  productKey: string;
  merchandisableProductName: string
}

const ProductContext = createContext<ProductContextProps | undefined>(undefined)

export const useProductData = (): ProductContextProps => {
  const context = useContext(ProductContext)
  if (!context) {
    throw new Error('useProductContext must be used within a ProductProvider')
  }
  return context
}

export const ProductProvider = ({
  children, mpvId, selectedOptions, productKey, merchandisableProductName
}: PropsWithChildren<ProductContextProps>) => {
  return (
    <ProductContext.Provider value={{ mpvId, selectedOptions, productKey, merchandisableProductName }}>
      {children}
    </ProductContext.Provider>
  )
}
