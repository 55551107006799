import { getTracking, Tracking } from '@vp/tracking'

export const getInitializedTracking: () => Promise<Tracking> = async () => {
  const tracking = getTracking()

  // If tracking is already initialized then return it
  if (tracking) {
    return tracking
  }

  // If tracking is not initialized then listen for trackingReady event
  return new Promise<Tracking>((resolve) => {
    window.addEventListener('trackingReady', () => {
      const initializedTracking = getTracking()
      if (!initializedTracking) {
        throw new Error('Tracking is not initialized')
      }
      resolve(initializedTracking)
    })
  })
}
