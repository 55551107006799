import { clientMount } from '@vp/ubik-fragment-react'
import type { MountWithRootOptions } from '@vp/ubik-fragment-types'
import { CalendarsFragment, type CalendarFragmentProps } from '../fragments/CalendarsFragment'
import { RedirectFragment, RedirectFragmentProps } from '../fragments/RedirectFragment'

export const mount: MountWithRootOptions<CalendarFragmentProps | RedirectFragmentProps> = async (rootElement, renderProps, rootOptions) => {
  if ('redirectUrl' in renderProps) {
    return clientMount(<RedirectFragment {...renderProps} />, rootElement, rootOptions)
  }

  return clientMount(<CalendarsFragment {...renderProps} />, rootElement, rootOptions)
}
