import { useProductData } from '../../context/ProductContext'
import { getInitializedTracking } from '../../utils/tracking'

export const useTrackStudioPageView = (predesignId: string) => {
  const { mpvId, selectedOptions, productKey, merchandisableProductName } = useProductData()
  const size = selectedOptions['Size']

  return () => {
    getInitializedTracking().then((tracking) => {
      tracking.track('Product Viewed', {
        label: 'Studio page view', // the event label name
        pageSection: 'Studio', // the section of the page
        pageStage: 'Design', // the stage of the page
        pageName: `${mpvId}:Studio`, // detailed name of the page where the event has been sent
        product_id: mpvId, // MPVID
        name: merchandisableProductName, // Detailed Product Name / MPV
        core_product_id: productKey, // Set to custom dimension
        variant: `templateId:${predesignId}_Size:${size}`,
      })
    })
  }
}
