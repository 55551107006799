import { useEffect } from 'react'
import { useProductData } from '../../context/ProductContext'
import { getInitializedTracking } from '../../utils/tracking'

function CalendarsProductViewedEvent (
  mpvId: string,
  localizedProductName: string,
  coreProductId: string,
  products: any[],
  previousNumberOfImageInDisplay: number
) {
  getInitializedTracking().then((tracking) => {
    tracking.track('Product List Viewed', {
      label: 'Gallery View',
      list_id: 'Gallery',
      pageSection: 'Gallery',
      pageStage: 'Discover',
      pageName: `${mpvId}:Option Selection:Gallery`,
      products: products.map((product, index) => {
        return {
          product_id: mpvId,
          name: localizedProductName,
          core_product_id: coreProductId,
          position: previousNumberOfImageInDisplay + index + 1,
          variant: `templateId:${product.predesignId}_Size:${product.sizes}`, // todo - pretty sure size is broken
        }
      }),
    })
  })
}

export const useTrackingProductListViewedEvent = (numberOfImageInDisplay: number, designs: any[]) => {
  const { mpvId, selectedOptions, productKey, merchandisableProductName } = useProductData()

  useEffect(() => {
    // TODO - idk why we are filtering on size option
    if (selectedOptions['Size']) {
      CalendarsProductViewedEvent(
        mpvId,
        merchandisableProductName,
        productKey,
        designs,
        0 // TODO- THIS WILL ALWAYS START AT 1 because we have redirect now
      )
    }
  }, [numberOfImageInDisplay])
}
