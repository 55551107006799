import { BoundedContent, Spinner, Typography } from '@vp/swan'
import { useSwanStyleKeys } from '@vp/ubik-context'
import { useEffect } from 'react'

export interface RedirectFragmentProps {
  redirectUrl: string,
  loadingText: string
}
export const RedirectFragment = ({ redirectUrl, loadingText }: RedirectFragmentProps) => {
  useSwanStyleKeys(['spinner'])

  useEffect(() => {
    if (window) {
      window.location.href = redirectUrl
    }
  }, [redirectUrl])

  return (
    <BoundedContent textAlign='center'>
      <Spinner accessibilityLabel={loadingText} size='super' />
      <Typography variant='body1'>
        {loadingText}
      </Typography>
    </BoundedContent>
  )
}
